<template>
  <layout-default page-title="List Pages" page-id="listPagesPage">
    <v-data-table
        :expanded.sync="expanded"
        show-expand
        :single-expand="singleExpand"
        id="dataTable"
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="bannerId"
    >
      <template v-slot:[`item.fileUrl`]="{ item }">
        <div class="p-2 d-flex justify-content-center">
          <v-img v-if="item.fileUrl != null" :src="$replaceHttps(item.fileUrl)" height="130"
                 width="130" contain>
          </v-img>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>
          <div v-if="item.description === null">
            Without Description
          </div>
          <div v-else>
            <div v-html="item.description"></div>
          </div>
        </td>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            color="primary"
            elevation="0"
            :to="{path: 'definePage',
            query:{isNew:false , id:item.pageId}}"
        >
          <i class="fas fa-edit white--text"></i>
        </v-btn>
      </template>
      <template v-slot:[`item.typePage`]="{ item }">
        <div class="p-2 d-flex justify-content-center">
          {{ $typePage[item.typePage - 1].name }}
        </div>
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <div v-if="item.link !== null">
          <a :href="item.link" target="_blank">
            <v-btn
                color="primary"
                elevation="0"
            >
              <i class="fas fa-link white--text"></i>
            </v-btn>
          </a>
        </div>
      </template>
    </v-data-table>

  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ListPages',
  components: {LayoutDefault},
  data: () => ({
    loading: true,
    headers: [
      {text: 'Page Type', value: 'typePage', sortable: false,},
      {text: 'Image', value: 'fileUrl', sortable: false,},
      {text: 'Description', value: 'data-table-expand', sortable: false,},
      {text: 'Link', value: 'link', sortable: false,},
      {text: 'Edit', value: 'actions', sortable: false,},
    ],
    list: [],
    expanded: [],
    singleExpand: true,
  }),
  mounted() {
    this.getData();
  },
  methods:{
    getData() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/PagesList", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result)
            this.loading = false
          })
          .catch(error => console.log('error', error));
    },

  }
};
</script>
