<template>
  <layout-default page-title="Social Network" page-id="defineSocialPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-12 text-center py-0">
                <div class="d-flex justify-content-center">
                  <input v-show="false" id="ImageFileUrl" ref="inputUpload"
                         name="ImageFileUrl" type="file"
                         @change="attachmentSelected">
                  <div v-if="selectedImage !==null">
                    <img
                        :src="selectedImage"
                        class="img-fluid p-3" alt="Social Image"/>
                  </div>
                  <div v-else class="no-pic-height">Please select an image</div>
                </div>
                <div class="p-3 text-center">
                  <v-btn
                      dark
                      elevation="0"
                      class="primary rounded-lg"
                      @click="$refs.inputUpload.click()">
                    Select Image
                  </v-btn>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <v-text-field
                    v-model="name"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Name">
                </v-text-field>
              </div>
              <div class="col-md-4 col-12">
                <v-text-field
                    v-model="link"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    placeholder="e.g. https://google.com"
                    label="Link">
                </v-text-field>
              </div>
              <div class="col-md-4 col-12">
                <v-checkbox hide-details
                            class="mt-0"
                            v-model="isActive"
                            color="primary">
                  <template v-slot:label>
                    <span class="mt-2">Active</span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <v-btn v-if="isNew"
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>
                <v-btn v-else
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="applyEdit">
                  <span class="white--text">Apply Edit</span>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';

export default {
  name: 'DefineSocial',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    isFormValid: false,
    loading: false,
    isNew: false,

    selectedImage: null,
    name: null,
    link: null,
    description: null,
    isActive: false,

    showResultDialog: null,
    dialogResult: null,


  }),
  mounted() {
    if (this.$route.query.isNew === 'true') {
      this.isNew = true
      this.description = new Editor({
        content: '',
      })
    } else {
      this.getData();
      this.isNew = false
    }
  },
  methods: {
    attachmentSelected(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.hasPhoto = false;
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.selectedImage = fr.result
          this.selectedImageFilePath = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.selectedImageFilePath = null;
      }
    },
    submitNew() {
      this.loading = true;
      let resStatus = null;

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      if (this.description !== '') {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("ImageFileUrl", this.selectedImageFilePath);
      formdata.append("Link", this.link);
      formdata.append("Name", this.name);
      formdata.append("IsActive", this.isActive);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/DefineSocialNetwork", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.loading = false;
              this.$router.push({path: '/Admin/ListSocials'});
            } else {
              this.loading = false;
              this.dialogResult = result;
              this.showResultDialog = true;
            }
          })
          .catch(error => console.log('error', error));


    },
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditSocialNetwork?socialNetworkId=" + this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)
            console.log(json)
            this.loading = false
            this.name = json.name
            this.link = json.link
            this.isActive = json.isActive
            this.description = new Editor({
              content: json.description,
            })
            this.selectedImage = json.fileUrl
            if (json.fileUrl !== null) {
              this.hasPhoto = true
            }

          })
          .catch(error => console.log('error', error));
    },
    applyEdit() {
      let resStatus = null

      this.showLoaderDialog = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      if (this.description !== '') {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("SocialNetworkId", this.$route.query.id);
      if (this.hasPhoto) {
        formdata.append("FileUrl", this.selectedImage);
      } else if (this.selectedImageFilePath !== null) {
        formdata.append("ImageFileUrl", this.selectedImageFilePath);
      }
      formdata.append("Link", this.link);
      formdata.append("Name", this.name);
      formdata.append("IsActive", this.isActive);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditSocialNetwork", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.loading = false
              this.$router.push({path: '/Admin/ListSocials'});
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }

          })
          .catch(error => console.log('error', error));
    },
  },

};
</script>
