<template>
  <layout-default page-title="Define Banner" page-id="defineBannerPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-12 text-center py-0">
                <input v-show="false" id="ImageFileUrl" ref="inputUpload"
                       name="ImageFileUrl" type="file"
                       @change="attachmentSelected">
                <div v-if="selectedImage !==null">
                  <img
                      :src="selectedImage"
                      class="img-fluid p-3" alt="Banner Image"/>
                </div>
                <div v-else class="no-pic-height">
                  Please select an image for the banner
                </div>
                <div class="p-3 text-center">
                  <v-btn
                      dark
                      elevation="0"
                      class="primary rounded-lg"
                      @click="$refs.inputUpload.click()">
                    Select Image
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </div>
              <div class="col-md-6 col-12">
                <v-checkbox hide-details
                            class="mt-0"
                            v-model="isActive"
                            color="primary">
                  <template v-slot:label>
                    <span class="mt-2">Active</span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="link"
                    dense
                    flat
                    outlined
                    color="primary"
                    placeholder="e.g. https://google.com"
                    label="Link">
                </v-text-field>
              </div>
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <v-btn v-if="isNew"
                    :disabled="!hasPhoto"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>
                <v-btn v-else
                       :disabled="!hasPhoto"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="applyEdit">
                  <span class="white--text">Apply Edit</span>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';

export default {
  name: 'DefineBanner',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    loading: false,
    isFormValid: false,
    selectedImage: null,
    selectedImageFilePath: null,
    hasPhoto: false,
    link: null,
    isActive: false,
    description: null,
    isNew: false,
    showResultDialog: false,
    dialogResult: null
  }),
  mounted() {
    if (this.$route.query.isNew === 'true') {
      this.isNew = true
      this.description = new Editor({
        content: '',
      })
    }else{
      this.getData();
      this.isNew = false
    }
  },
  methods: {
    attachmentSelected(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.hasPhoto = false;
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.selectedImage = fr.result
          this.selectedImageFilePath = files[0] // this is an image file that can be sent to server...
          this.hasPhoto = true;
        })
      } else {
        this.selectedImageFilePath = null;
      }
    },
    submitNew(){
      this.loading = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      if (this.description !== null) {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("IsActive", this.isActive);
      formdata.append("ImageFileUrl", this.selectedImageFilePath);
      if (this.link !== null) {
        formdata.append("Link", this.link);
      }

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/DefineBanner", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false

            if (resStatus === 200) {
              this.dialogResult = result
              this.showResultDialog = true
              this.$router.push({path: '/Admin/ListBanners'})
            } else {
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));


    },
    getData(){
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditBanner?bannerId=" + this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)
            this.description = new Editor({
              content: json.description,
            })
            this.link = json.link
            this.selectedImage = json.fileUrl
            if (json.fileUrl !== null) {
              this.hasPhoto = true
            }
            this.isActive = json.isActive;
            this.loading = false;
          })
          .catch(error => console.log('error', error));
    },
    applyEdit(){
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      formdata.append("BannerId", this.$route.query.id);
      if (this.description !== null) {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("IsActive", this.isActive);
      if (this.hasPhoto) {
        formdata.append("FileUrl", this.selectedImage);
      } else if (this.selectedImageFilePath !== null) {
        formdata.append("ImageFileUrl", this.selectedImageFilePath);
      }
      if (this.link !== null) {
        formdata.append("Link", this.link);
      }

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(this.$baseUrl + "Admin/EditBanner", requestOptions)
          .then(response => response.text())
          .then(() => {
            this.loading = false
            this.$router.push({path: '/Admin/ListBanners'})
          })
          .catch(error => console.log('error', error));

    }
  }
};
</script>
