<template>
  <layout-default page-title="Logout" page-id="logoutPage">
    <v-alert
        dense
        text
        outlined
        color="primary"
        class="text-center">
      Are your sure, you want to logout?
      <v-divider></v-divider>
      <div class="row">
        <div class="col-6 text-right">
          <v-btn
              color="primary"
              elevation="0"
              rounded
              @click="$router.go(-1);">
            <span class="white--text">Back</span>
          </v-btn>
        </div>
        <div class="col-6 text-left">
          <v-btn
              color="orange"
              elevation="0"
              rounded
              @click="logout">
            <span class="white--text">Logout</span>
          </v-btn>

        </div>
      </div>
    </v-alert>

  </layout-default>
</template>


<script>
import LayoutDefault from "@/components/layout-default.vue";


export default {
  name: 'LogoutPage',
  components: {LayoutDefault},
  methods: {
    logout() {
      this.$setUserData('', '', '', '');
      this.$router.replace({path: '/'})
    }
  }
};
</script>
