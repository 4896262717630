<template>
  <layout-default page-title="Order Detail" page-id="orderDetailPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title v-if="order !== null" class="pa-1">
          <div class="row">
            <div class="col-6 py-1">
              <span class="font-weight-normal">Subject: </span>
              <span class="primary-text">{{ order.subject }}</span>
            </div>
            <div class="col-6 py-1">
              <span class="font-weight-normal">Order Number: </span>
              <span class="primary-text">{{ order.orderNumber }}</span>
            </div>
            <div class="col-12 col-sm-6 py-1">
              <span class="font-weight-normal">Status: </span>
              <span class="primary-text">{{ $typeOrderStatus[order.typeOrderStatus - 1].text }}</span>
            </div>
            <div class="col-12 col-sm-6 py-1">
              <span class="font-weight-normal">Service: </span>
              <span class="primary-text">{{ order.serviceItem.name }}</span>
            </div>
            <div class="col-12 col-sm-6 py-1">
              <span class="font-weight-normal">Submitted: </span>
              <span class="primary-text">{{ $displayDateTime(order.createdDate) }}</span>
            </div>
            <div class="col-12 col-sm-6 py-1">
              <span class="font-weight-normal">Updated: </span>
              <span class="primary-text">{{ $displayDateTime(order.updatedDate) }}</span>
            </div>
            <div class="col-6 py-1">
              <span class="font-weight-normal">Price: </span>
              <span class="primary-text">{{ $numberWithCommas(order.totalAmount) }}</span>
            </div>
            <div class="col-6 py-1">
              <span class="font-weight-normal">Paid: </span>
              <span class="primary-text">
                <span v-if="order.isPayed === true">Yes</span>
                <span v-else class="red--text">No</span>
              </span>
            </div>
            <div class="col-12 text-center">
              <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="showChangeStatusDialog =  true">
                Change Status
              </v-btn>
              <v-divider></v-divider>
            </div>

            <div class="col-12 ma-0" v-if="order.description !== '<p></p>'" v-html="order.description">
            </div>
            <div class="col-6 col-md-4 mx-auto" v-if="order.fileUrl1 !== null">
              <v-btn block color="primary"
                     :href="order.fileUrl1"
                     target="_blank"
                     class="white--text">
                Attachment No.1
              </v-btn>
            </div>
            <div class="col-6 col-md-4 mx-auto" v-if="order.fileUrl2 !== null">
              <v-btn block color="primary"
                     :href="order.fileUrl2"
                     target="_blank"
                     class="white--text">
                Attachment No.2
              </v-btn>
            </div>
            <div class="col-6 col-md-4 mx-auto" v-if="order.fileUrl3 !== null">
              <v-btn block color="primary"
                     :href="order.fileUrl3"
                     target="_blank"
                     class="white--text">
                Attachment No.3
              </v-btn>
            </div>
            <div class="col-6 col-md-4 mx-auto" v-if="order.fileUrl4 !== null">
              <v-btn block color="primary"
                     :href="order.fileUrl4"
                     target="_blank"
                     class="white--text">
                Attachment No.4
              </v-btn>
            </div>
            <div class="col-6 col-md-4 mx-auto" v-if="order.fileUrl5 !== null">
              <v-btn block color="primary"
                     :href="order.fileUrl5"
                     target="_blank"
                     class="white--text">
                Attachment No.5
              </v-btn>
            </div>

          </div>
          <v-divider></v-divider>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid"
                  v-if="order !== null && order.typeOrderStatus !== 8 && order.typeOrderStatus !== 9">
            <div class="row">
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
                <span class="red--text" v-if="description!== null && description.getHTML() === '<p></p>'">
                  Please enter a description.
                </span>
              </div>
              <div class="col-12 text-center">
                <div class="my-2">You may upload up to 5 files</div>
                <div class="row">
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput1"
                                  name="fileInput1" placeholder="File No.1" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput2"
                                  name="fileInput2" placeholder="File No.2" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput3"
                                  name="fileInput3" placeholder="File No.3" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput4"
                                  name="fileInput4" placeholder="File No.4" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput5"
                                  name="fileInput5" placeholder="File No.5" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                </div>

              </div>
              <div class="col-12 text-center">
                <v-btn
                    :disabled="description!== null && description.getHTML() === '<p></p>'"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submitReply">
                  <span class="white--text">Submit</span>
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
          </v-form>

          <div class="row" v-if="orderComments.length > 0">
            <div class="col-12 px-0" v-for="item in orderComments" :key="item.orderCommentId">
              <v-card>
                <v-card-title class="row primary-bg white--text" v-if="item.typeRole === 3">
                  <div class="col-6 p-0 text-left">
                    {{ appUser.name + " " + appUser.family }} - Customer
                  </div>
                  <div class="col-6 p-0 text-right">
                    {{ $displayDateTime(item.createdDate) }}
                  </div>
                </v-card-title>
                <v-card-title class="row secondary-bg white--text" v-else>
                  <div class="col-6 p-0 text-left">
                    {{ agent.name + " " + agent.family }} - Operator
                  </div>
                  <div class="col-6 p-0 text-right">
                    {{ $displayDateTime(item.createdDate) }}
                  </div>
                </v-card-title>
                <v-card-text class="row">
                  <div class="col-12 ma-0" v-html="item.description">
                  </div>
                  <div class="col-6 col-md-4 mx-auto" v-if="item.fileUrl1 !== null">
                    <v-btn block color="primary"
                           :href="item.fileUrl1"
                           target="_blank"
                           class="white--text">
                      Attachment No.1
                    </v-btn>
                  </div>
                  <div class="col-6 col-md-4 mx-auto" v-if="item.fileUrl2 !== null">
                    <v-btn block color="primary"
                           :href="item.fileUrl2"
                           target="_blank"
                           class="white--text">
                      Attachment No.2
                    </v-btn>
                  </div>
                  <div class="col-6 col-md-4 mx-auto" v-if="item.fileUrl3 !== null">
                    <v-btn block color="primary"
                           :href="item.fileUrl3"
                           target="_blank"
                           class="white--text">
                      Attachment No.3
                    </v-btn>
                  </div>
                  <div class="col-6 col-md-4 mx-auto" v-if="item.fileUrl4 !== null">
                    <v-btn block color="primary"
                           :href="item.fileUrl4"
                           target="_blank"
                           class="white--text">
                      Attachment No.4
                    </v-btn>
                  </div>
                  <div class="col-6 col-md-4 mx-auto" v-if="item.fileUrl5 !== null">
                    <v-btn block color="primary"
                           :href="item.fileUrl5"
                           target="_blank"
                           class="white--text">
                      Attachment No.5
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showChangeStatusDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Payment</span>
          <v-btn
              icon
              small
              @click="showChangeStatusDialog =  false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 pb-0">
          <div class="mb-0 black--text">
            <v-autocomplete
                :items="$typeOrderStatus"
                v-model="selectedStatus"
                dense
                flat
                outlined
                clearable
                hide-details
                color="primary"
                item-text="text"
                item-value="id"
                label="Order Status">
            </v-autocomplete>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-card-subtitle>
          <div class="row">
            <div class="col-6 text-left py-0 ">
              <v-btn color="orange"
                     elevation="0"
                     rounded
                     @click="showChangeStatusDialog = false">
                <span class="white--text">Dismiss</span>
              </v-btn>
            </div>
            <div class="col-6 text-right py-0">
              <v-btn
                  :disabled="selectedStatus == null"
                  color="primary"
                  elevation="0"
                  rounded
                  @click="changeOrderStatus">
                <span class="white--text">Apply</span>
              </v-btn>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showUploadDialog"
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Uploading</span>

        </v-card-title>
        <v-card-text class="pt-6">
          <v-alert
              border="top"
              class="mt-1 text-center"
              color="secondary"
              dark>
            Please wait.
            <br/>
            Do not leave or close this page, until the upload is completed, otherwise upload will be canceled.
          </v-alert>

          <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="uploadProgress"
              color="primary"
          >
            {{ uploadProgress }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';
import axios from "axios";

export default {
  name: 'AdminOrderDetails',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    order: null,
    orderComments: [],

    loading: false,
    isFormValid: false,

    description: null,

    showResultDialog: false,
    dialogResult: null,

    agent: null,
    appUser: null,

    showChangeStatusDialog: false,
    selectedStatus: null,

    showUploadDialog:false,
    uploadProgress:0

  }),
  mounted() {
    this.getData();
    this.description = new Editor({
      content: '',
    })
  },
  methods: {
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/OrderDetail?orderId=" +
          this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {

            let json = JSON.parse(result);
            this.order = json.order;
            this.orderComments = json.orderComments;
            this.appUser = json.order.appUser;
            this.agent = json.order.agentUser;
            this.loading = false;
            //this.modifiedDate = this.$displayDateTime(json.modifiedDate)

          })
          .catch(error => console.log('error', error));
    },
    submitReply() {

      let formdata = new FormData();

      formdata.append("OrderId", this.$route.query.id);
      formdata.append("Description", this.description.getHTML());

      if (document.getElementById('fileInput1').files[0] !== undefined) {
        formdata.append("File1", document.getElementById('fileInput1').files[0]);
      }
      if (document.getElementById('fileInput2').files[0] !== undefined) {
        formdata.append("File2", document.getElementById('fileInput2').files[0]);
      }
      if (document.getElementById('fileInput3').files[0] !== undefined) {
        formdata.append("File3", document.getElementById('fileInput3').files[0]);
      }
      if (document.getElementById('fileInput4').files[0] !== undefined) {
        formdata.append("File4", document.getElementById('fileInput4').files[0]);
      }
      if (document.getElementById('fileInput5').files[0] !== undefined) {
        formdata.append("File5", document.getElementById('fileInput5').files[0]);
      }

      this.showUploadDialog = true;

      axios.post(this.$baseUrl + "Admin/ReplyOrder",
          formdata, // the data to post
          {
            headers: {
              'Content-type': 'multipart/form-data',
              'Authorization': this.$getUserData().token,
            },
            onUploadProgress: progressEvent => {
              this.uploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            }
          }).then(response => {
        this.showUploadDialog = false;
        this.dialogResult = response.data
        this.showResultDialog = true;
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
          .catch(error => {
            this.showUploadDialog = false;
            if (error.response?.status === 413) {
              this.dialogResult = "ّFile size exceeded"
            } else {
              this.dialogResult = error.message
            }
            this.showResultDialog = true
          });

      /*

            let requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
            };

            fetch(this.$baseUrl + "Admin/ReplyOrder", requestOptions)
                .then(response => {
                  resStatus = response.status
                  return response.text()
                })
                .then(result => {
                  this.loading = false
                  if (resStatus === 200) {
                    window.location.reload();
                  } else {
                    this.dialogResult = result
                    this.showResultDialog = true
                  }
                })
                .catch(error => console.log('error', error));
      */


    },

    submitReplyOld() {
      this.loading = true;
      let resStatus = null

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();

      formdata.append("OrderId", this.$route.query.id);
      formdata.append("Description", this.description.getHTML());

      if (document.getElementById('fileInput1').files[0] !== undefined) {
        formdata.append("File1", document.getElementById('fileInput1').files[0]);
      }
      if (document.getElementById('fileInput2').files[0] !== undefined) {
        formdata.append("File2", document.getElementById('fileInput2').files[0]);
      }
      if (document.getElementById('fileInput3').files[0] !== undefined) {
        formdata.append("File3", document.getElementById('fileInput3').files[0]);
      }
      if (document.getElementById('fileInput4').files[0] !== undefined) {
        formdata.append("File4", document.getElementById('fileInput4').files[0]);
      }
      if (document.getElementById('fileInput5').files[0] !== undefined) {
        formdata.append("File5", document.getElementById('fileInput5').files[0]);
      }

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/ReplyOrder", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false
            if (resStatus === 200) {
              window.location.reload();
            } else {
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));


    },
    changeOrderStatus() {
      this.loading = true;
      let resStatus = null

      console.log("this.description.getHTML()");
      console.log(this.description.getHTML());

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();

      formdata.append("OrderId", this.$route.query.id);
      formdata.append("typeOrderStatus", this.selectedStatus);


      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/ChangeOrderStatus", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false
            if (resStatus === 200) {
              window.location.reload();
            } else {
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
