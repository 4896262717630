<template>
  <layout-default page-id="dashboardPage" page-title="Dashboard">
    <div class="row" v-if="content !== null">
      <div class="col-md-3 col-6 text-center ">
        <v-card color="teal">
          <router-link to="/Admin/NewOrders">
            <v-card-title class=" white--text text-center d-block">
              <div>New Orders</div>
              <v-divider></v-divider>
              <div>{{ content.newCount }}</div>
            </v-card-title>
          </router-link>
        </v-card>
      </div>
      <div class="col-md-3 col-6 text-center ">
        <v-card color="teal">
          <router-link to="/Admin/ClientPayments">
            <v-card-title class=" white--text text-center d-block">
              <div>New Orders Amount</div>
              <v-divider></v-divider>
              <div>{{ content.newSum }}</div>
            </v-card-title>
          </router-link>
        </v-card>
      </div>
      <div class="col-md-3 col-6 text-center ">
        <v-card color="teal">
          <router-link to="/Admin/ClientOrders">
            <v-card-title class=" white--text text-center d-block">
              <div>All Orders</div>
              <v-divider></v-divider>
              <div>{{ content.totalCount }}</div>
            </v-card-title>
          </router-link>
        </v-card>
      </div>
      <div class="col-md-3 col-6 text-center ">
        <v-card color="teal">
          <router-link to="/Admin/ClientPayments">
            <v-card-title class=" white--text text-center d-block">
              <div>All Orders Amount</div>
              <v-divider></v-divider>
              <div>{{ content.sum }}</div>
            </v-card-title>
          </router-link>
        </v-card>
      </div>
    </div>
  </layout-default>
</template>


<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'DashboardPage',
  components: {LayoutDefault},
  data: () => ({
    content: null,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/Dashboard", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.content = JSON.parse(result);
          })
          .catch(error => console.log('error', error));
    }
  }
};

</script>
