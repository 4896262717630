<template>
  <div class="container-fluid" id="loginPage">
    <div class="row">
      <div class="col-11 col-sm-7 col-md-6 mx-auto mt-0 mt-sm-5">
        <v-card class="login-card mt-7 text-center" flat>
          <v-card-title class="justify-content-center mx-15">
            <v-img contain src="../assets/img/logo-type.jpg"></v-img>
          </v-card-title>
          <v-card-text class="px-md-8 py-8">
            <v-form ref="form" v-model="isFormValid">
              <v-text-field
                  v-model="email"
                  :rules="$emailRules"
                  class="mb-3"
                  type="email"
                  dense
                  flat
                  outlined
                  color="primary"
                  label="E-mail">
              </v-text-field>
              <v-text-field
                  v-model="password"
                  :rules="$mandatory"
                  class="mb-3"
                  dense
                  flat
                  outlined
                  label="Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' :  'password'">
              </v-text-field>
              <div class="my-3 text-center">
                <v-btn :disabled="!isFormValid"
                       class="px-16 "
                       color="primary"
                       elevation="0"
                       rounded
                       @click="login">
                  <span class="white--text">Login</span>
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class='row'>
            <div class="col-12 px-md-8">
              <span class="pointer primary-text" @click="forgetPasswordDialog =true">
                  Recover Your Password
                </span>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <v-dialog
        v-model="forgetPasswordDialog"
        hide-overlay
        persistent
        width="500">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Recover Your Password</span>
          <v-btn
              icon
              small
              @click="forgetPasswordDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="recoverFrom" v-model="recoverFromValid">
            Please Enter your E-mail address
            <div class="mb-0 black--text py-5">
              <v-text-field
                  v-model="email"
                  :rules="$emailRules"
                  class="mb-3"
                  type="email"
                  dense
                  flat
                  outlined
                  color="primary"
                  label="E-mail">
              </v-text-field>
            </div>
            <v-btn :disabled="!recoverFromValid"
                   class="px-16 "
                   color="primary"
                   elevation="0"
                   rounded
                   @click="recoverPassword">
              <span class="white--text">Recover</span>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay
        id="loginLoader"
        v-model="showLoaderDialog"
        persistent
        width="500">
      <v-progress-circular
          color="white"
          indeterminate
          size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  metaInfo() {
    return {
      title: window.projectName + this.$route.name,
    }
  },
  data: () => ({
    isFormValid: false,
    email: null,
    password: null,

    forgetPasswordDialog: false,
    recoverFromValid: false,
    showLoaderDialog: false,
    showResultDialog: false,
    dialogResult: null,
    showPassword: false

  }),
  mounted() {
    if (this.$getUserData().email) {
      this.silentLogin();
    }
  },
  methods: {
    login() {
      this.showLoaderDialog = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("email", this.email);
      urlencoded.append("password", this.password);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/Login", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.showLoaderDialog = false
            if (resStatus !== 200) {
              this.dialogResult = result
              this.showResultDialog = true
            } else {
              let json = JSON.parse(result)
              const token = 'Bearer ' + json.jwtToken;
              this.$setUserData(this.email, this.password, token, json.role);
              if(json.role === 3){
                this.$router.push({path: '/Dashboard'})
              }else{
                this.$router.push({path: '/Admin/Dashboard'})
              }
            }
          })
          .catch(error => console.log('error', error));
    },
    recoverPassword() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("email", this.email);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/RecoverByEmail", requestOptions)
          .then(response => {
            return response.text()
          })
          .then(result => {
            this.showLoaderDialog = false
            this.dialogResult = result
            this.showResultDialog = true
          })
          .catch(error => console.log('error', error));
    },
    silentLogin() {
      this.showLoaderDialog = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const savedData = this.$getUserData();

      let urlencoded = new URLSearchParams();
      urlencoded.append("email", savedData.email);
      urlencoded.append("password", savedData.password);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/Login", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.showLoaderDialog = false
            if (resStatus === 200) {
              let json = JSON.parse(result)
              const token = 'Bearer ' + json.jwtToken;
              this.$setUserData(savedData.email, savedData.password, token, json.role);
              if(json.role === 3){
                this.$router.push({path: '/Dashboard'})
              }else{
                this.$router.push({path: '/Admin/Dashboard'})
              }
            }
          })
          .catch(error => console.log('error', error));
    },
  }
};
</script>

