<template>
    <div class="text-center">
        <div class="my-2 font-16 mr-1">
            {{label}}
        </div>
        <v-btn
                color="primary"
                class="white--text mb-3"
                @click="show = true">
            <i class="fas fa-image mr-2"></i>
            Select
        </v-btn>
        <my-upload field="img"
                   @crop-success="cropSuccess"
                   v-model="show"
                   :width="300"
                   :height="300"
                   :no-circle="true"
                   lang-type="en"
                   img-format="jpg"></my-upload>
        <img v-if="imgData" :src="imgData" @click="show= true" :alt="label"
             class="d-block img-fluid mx-auto">
    </div>
</template>

<script>
import myUpload from "vue-image-crop-upload/upload-2.vue";

export default {
    name: "ImagePicker",
    components: {myUpload},
    props: ['imgDataUrl', 'title'],
    data: () => ({
        imageBlob: null,
        show: false,
        label: "Image",
        imgData:null
    }),
    mounted() {
        if(this.$props.title !== undefined){
            this.label = this.$props.title
        }
        if(this.$props.imgDataUrl !== undefined){
            this.imgData = this.$props.imgDataUrl
        }
    },
    watch: {
        imgDataUrl: function(newVal) { // watch it
            if(newVal !== undefined){
                this.imgData = newVal
            }
        }
    },
    methods: {
        cropSuccess(imgDataUrl) {
            this.imgData = imgDataUrl;
            fetch(imgDataUrl)
                .then(res => res.blob())
                .then(blob => {
                    this.$emit("cropSuccess", blob);
                });
        },
    }
}
</script>

<style>

.vicp-wrap {
  max-width: 100%;
}
</style>
