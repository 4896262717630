<template>
  <layout-default page-title="Users List" page-id="listUsersPage">
    <div class="row">
      <div class="col-12">
        <v-autocomplete
            :items="$roles"
            v-model="typeRole"
            dense
            flat
            outlined
            color="primary"
            item-text="name"
            item-value="id"
            @change="getData"
            label="User Role">
        </v-autocomplete>
      </div>
      <div class="col-12">
        <v-data-table
            :expanded.sync="expanded"
            show-expand
            :single-expand="singleExpand"
            id="dataTable"
            :headers="headers"
            :items="list"
            :items-per-page="10"
            :light="true"
            :mobile-breakpoint="680"
            class="data-table"
            :loading="loading"
            fixed-header
            item-key="bannerId"
        >
          <template v-slot:[`item.fileUrl`]="{ item }">
            <div class="p-2 d-flex justify-content-center">
              <v-img v-if="item.fileUrl != null" :src="$replaceHttps(item.fileUrl)" height="130"
                     width="130" contain>
              </v-img>
            </div>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td>
              <div v-if="item.description === null">
                Without Description
              </div>
              <div v-else>
                <div v-html="item.description"></div>
              </div>
            </td>
          </template>

          <template v-slot:[`item.isActive`]="{ item }">
            <div v-if="item.isActive === true">Yes</div>
            <div v-else>No</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
                color="primary"
                elevation="0"
                :to="{path: 'DefineUser',
                 query:{isNew:false , id:item.id}}"
            >
              <i class="fas fa-edit white--text"></i>
            </v-btn>

          </template>

          <template v-slot:[`item.typeRole`]="{ item }">
            <span>
              {{ $roles[item.typeRole - 1].name }}
            </span>
          </template>

        </v-data-table>
      </div>
    </div>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ListUsers',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    headers: [
      {text: 'Name', value: 'name', sortable: false,},
      {text: 'Family', value: 'family', sortable: false,},
      {text: 'Image', value: 'fileUrl', sortable: false,},
      {text: 'Active', value: 'isActive', sortable: false,},
      {text: 'Description', value: 'data-table-expand', sortable: false,},
      {text: 'Role', value: 'typeRole', sortable: false,},
      {text: 'Edit', value: 'actions', sortable: false,},
    ],
    list: [],
    changeIsActiveFalse: false,
    changeIsActiveTrue: true,
    expanded: [],
    singleExpand: true,
    typeRole: null
  }),
  mounted() {
    this.typeRole = this.$route.query.role;
    if (this.typeRole) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let postfix = "";
      if (this.typeRole) {
        postfix = "?TypeRole=" + this.typeRole;
      }

      fetch(this.$baseUrl + "Admin/ListUsers" + postfix, requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result);
            this.loading = false
          })
          .catch(error => console.log('error', error));
    },
  }

};
</script>
