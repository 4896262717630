<template>
  <layout-default page-title="New Order" page-id="newOrderPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-md-6 col-12">
                <v-autocomplete
                    :items="serviceItems"
                    v-model="selectedServiceId"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    item-text="name"
                    item-value="serviceItemId"
                    @change="serviceChange"
                    label="Service">
                </v-autocomplete>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="subject"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Subject">
                </v-text-field>
              </div>
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <div class="my-2">You may upload up to 5 files</div>
                <div class="row">
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput1"
                                  name="fileInput1" placeholder="File No.1" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput2"
                                  name="fileInput2" placeholder="File No.2" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput3"
                                  name="fileInput3" placeholder="File No.3" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput4"
                                  name="fileInput4" placeholder="File No.4" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                  <div class="col-6 col-md-4 mx-auto">
                    <v-file-input id="fileInput5"
                                  name="fileInput5" placeholder="File No.5" show-size outlined dense hide-details
                                  append-icon="fa-paperclip"
                                  prepend-icon=""></v-file-input>
                  </div>
                </div>

              </div>
              <div class="col-12 text-center " v-if="price">
                <v-alert
                    color="primary">
                  <span class="white--text">price: {{ price }}</span>
                </v-alert>
              </div>
              <div class="col-12 text-center">
                <v-btn
                    :disabled="!isFormValid"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>

              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showUploadDialog"
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Uploading</span>

        </v-card-title>
        <v-card-text class="pt-6">
          <v-alert
              border="top"
              class="mt-1 text-center"
              color="secondary"
              dark>
            Please wait.
            <br/>
            Do not leave or close this page, until the upload is completed, otherwise upload will be canceled.
          </v-alert>

          <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="uploadProgress"
              color="primary"
          >
            {{ uploadProgress }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';
import axios from "axios";

export default {
  name: 'NewOrder',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    isFormValid: false,
    loading: false,

    serviceItems: [],
    selectedServiceId: null,
    subject: null,
    description: null,
    price: null,

    showResultDialog: false,
    dialogResult: null,


    showUploadDialog:false,
    uploadProgress:0
  }),
  mounted() {
    this.getData();
    this.description = new Editor({
      content: '',
    })
  },
  methods: {
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/ActiveServiceItemsList", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.serviceItems = JSON.parse(result)
            this.loading = false

          })
          .catch(error => console.log('error', error));
    },
    serviceChange() {
      this.price = this.serviceItems.filter(x => x.serviceItemId === this.selectedServiceId)[0].price;
    },
    submitNew() {

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();

      if (this.description.getHTML() === '<p></p>') {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("Subject", this.subject);
      formdata.append("TotalAmount", this.price);
      formdata.append("ServiceItemId", this.selectedServiceId);

      if (document.getElementById('fileInput1').files[0] !== undefined) {
        formdata.append("File1", document.getElementById('fileInput1').files[0]);
      }
      if (document.getElementById('fileInput2').files[0] !== undefined) {
        formdata.append("File2", document.getElementById('fileInput2').files[0]);
      }
      if (document.getElementById('fileInput3').files[0] !== undefined) {
        formdata.append("File3", document.getElementById('fileInput3').files[0]);
      }
      if (document.getElementById('fileInput4').files[0] !== undefined) {
        formdata.append("File4", document.getElementById('fileInput4').files[0]);
      }
      if (document.getElementById('fileInput5').files[0] !== undefined) {
        formdata.append("File5", document.getElementById('fileInput5').files[0]);
      }

      this.showUploadDialog = true;

      axios.post(this.$baseUrl + "Client/NewOrder",
          formdata, // the data to post
          {
            headers: {
              'Content-type': 'multipart/form-data',
              'Authorization': this.$getUserData().token,
            },
            onUploadProgress: progressEvent => {
              this.uploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            }
          }).then(response => {
        this.showUploadDialog = false;
        this.dialogResult = response.data
        this.showResultDialog = true;
        this.$router.push({path: '/MyOrders'});
      })
          .catch(error => {
            this.showUploadDialog = false;
            if (error.response?.status === 413) {
              this.dialogResult = "ّFile size exceeded"
            } else {
              this.dialogResult = error.message
            }
            this.showResultDialog = true
          });


      /*let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/NewOrder", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false
            if (resStatus === 200) {
              this.$router.push({path: '/MyOrders'})
            } else {
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));*/

    }
  }
};
</script>
