import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/pages/LoginPage.vue";
import DashboardPage from "@/pages/admin/AdminDashboard.vue";
import LogoutPage from "@/pages/LogoutPage.vue";
import ProfilePage from "@/pages/admin/ProfilePage.vue";
import DefineBanner from "@/pages/admin/DefineBanner.vue";
import ListBanners from "@/pages/admin/ListBanners.vue";
import DefineServiceItem from "@/pages/admin/DefineServiceItem.vue";
import ListServiceItems from "@/pages/admin/ListServiceItems.vue";
import DefinePage from "@/pages/admin/DefinePage.vue";
import ListPages from "@/pages/admin/ListPages.vue";
import DefineSocial from "@/pages/admin/DefineSocial.vue";
import ListSocials from "@/pages/admin/ListSocials.vue";
import DefineUser from "@/pages/admin/DefineUser.vue";
import ListUsers from "@/pages/admin/ListUsers.vue";
import ClientDashboard from "@/pages/client/ClientDashboard.vue";
import MyProfile from "@/pages/client/MyProfile.vue";
import SettingsList from "@/pages/admin/SettingsList.vue";
import EditSetting from "@/pages/admin/EditSetting.vue";
import AboutUs from "@/pages/client/AboutUs.vue";
import ContactUs from "@/pages/client/ContactUs.vue";
import TermsConditions from "@/pages/client/TermsConditions.vue";
import AboutApp from "@/pages/client/AboutApp.vue";
import SocialNetworks from "@/pages/client/SocialNetworks.vue";
import SuggestionCritic from "@/pages/client/SuggestionCritic.vue";
import ListSuggests from "@/pages/admin/ListSuggests.vue";
import NewOrder from "@/pages/client/NewOrder.vue";
import MyOrders from "@/pages/client/MyOrders.vue";
import OrderDetails from "@/pages/client/OrderDetails.vue";
import PaymentHistory from "@/pages/client/PaymentHistory.vue";
import ClientOrders from "@/pages/admin/ClientOrders.vue";
import AdminOrderDetails from "@/pages/admin/AdminOrderDetails.vue";
import NewOrders from "@/pages/admin/NewOrders.vue";
import ClientPayments from "@/pages/admin/ClientPayments.vue";
import ExceptionLogs from "@/pages/admin/ExceptionLogs.vue";

Vue.use(VueRouter);

const routes = [
     {
        path: '/',
        redirect: '/Login',
        meta: {transition: 'fade'},
    },
    {
        path: "/Login",
        name: "Login",
        component: LoginPage,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/Dashboard",
        name: "Admin Dashboard",
        component: DashboardPage,
        meta: {transition: 'fade'},
    },
    {
        path: "/Logout",
        name: "Logout",
        component: LogoutPage,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/Profile",
        name: "Profile",
        component: ProfilePage,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/DefineBanner",
        name: "Define Banner",
        component: DefineBanner,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListBanners",
        name: "List Banners",
        component: ListBanners,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/DefineServiceItem",
        name: "Define Service Item",
        component: DefineServiceItem,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListServiceItems",
        name: "List Service Items",
        component: ListServiceItems,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/DefinePage",
        name: "Define Page",
        component: DefinePage,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListPages",
        name: "List Pages",
        component: ListPages,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/DefineSocial",
        name: "Define Social",
        component: DefineSocial,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListSocials",
        name: "Social Networks List",
        component: ListSocials,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/DefineUser",
        name: "Define User",
        component: DefineUser,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListUsers",
        name: "Users List",
        component: ListUsers,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/SettingsList",
        name: "Settings List",
        component: SettingsList,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/EditSetting",
        name: "Edit Setting",
        component: EditSetting,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ListSuggests",
        name: "Criticisms and Suggestions",
        component: ListSuggests,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ClientOrders",
        name: "Client Orders",
        component: ClientOrders,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/OrderDetails",
        name: "Order Details",
        component: AdminOrderDetails,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/NewOrders",
        name: "New Orders",
        component: NewOrders,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ClientPayments",
        name: "Client Payments",
        component: ClientPayments,
        meta: {transition: 'fade'},
    },
    {
        path: "/Admin/ExceptionLogs",
        name: "Exception Logs",
        component: ExceptionLogs,
        meta: {transition: 'fade'},
    },
    {
        path: "/Dashboard",
        name: "Dashboard",
        component: ClientDashboard,
        meta: {transition: 'fade'},
    },

    {
        path: "/Profile",
        name: "Profile",
        component: MyProfile,
        meta: {transition: 'fade'},
    },
    {
        path: "/AboutUs",
        name: "AboutUs",
        component: AboutUs,
        meta: {transition: 'fade'},
    },
    {
        path: "/ContactUs",
        name: "ContactUs",
        component: ContactUs,
        meta: {transition: 'fade'},
    },
    {
        path: "/TermsConditions",
        name: "TermsConditions",
        component: TermsConditions,
        meta: {transition: 'fade'},
    },
    {
        path: "/AboutApp",
        name: "AboutApp",
        component: AboutApp,
        meta: {transition: 'fade'},
    },
    {
        path: "/SocialNetworks",
        name: "SocialNetworks",
        component: SocialNetworks,
        meta: {transition: 'fade'},
    },
    {
        path: "/SuggestionCritic",
        name: "SuggestionCritic",
        component: SuggestionCritic,
        meta: {transition: 'fade'},
    },
    {
        path: "/NewOrder",
        name: "New Order",
        component: NewOrder,
        meta: {transition: 'fade'},
    },
    {
        path: "/MyOrders",
        name: "My Orders",
        component: MyOrders,
        meta: {transition: 'fade'},
    },
    {
        path: "/OrderDetails",
        name: "Order Details",
        component: OrderDetails,
        meta: {transition: 'fade'},
    },
    {
        path: "/PaymentHistory",
        name: "Payment History",
        component: PaymentHistory,
        meta: {transition: 'fade'},
    },
];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
