<template>
  <layout-default page-title="User" page-id="defineUserPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-12">
                <ImagePicker :imgDataUrl="fileUrl"
                             title="User Image"
                             @cropSuccess="blob => imageBlob = blob"/>
              </div>
              <div class="col-md-6 col-12">
                <v-autocomplete
                    :items="$roles"
                    v-model="typeRole"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    item-text="name"
                    item-value="id"
                    label="User Role">
                </v-autocomplete>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="email"
                    :rules="$emailRules"
                    type="email"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="E-mail">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="name"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Name">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="family"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Family">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="password"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    label="Password"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' :  'password'">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="nationalCode"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="National/Social Security Code">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="phoneNumber"
                    dense
                    flat
                    outlined
                    type="number"
                    color="primary"
                    label="Phone Number">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-checkbox hide-details
                            class="mt-0"
                            v-model="isActive"
                            color="primary">
                  <template v-slot:label>
                    <span class="mt-2">Active</span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <v-btn v-if="isNew"
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>
                <v-btn v-else
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="applyEdit">
                  <span class="white--text">Apply Edit</span>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import ImagePicker from "@/components/ImagePicker.vue";
import {Editor, EditorContent} from 'tiptap';

export default {
  name: 'DefineUser',
  components: {ImagePicker, LayoutDefault, EditorContent},
  data: () => ({
    isFormValid: false,
    loading: false,
    isNew: false,

    email: null,
    password: null,
    name: null,
    family: null,
    fileUrl: null,
    nationalCode: null,
    phoneNumber: null,
    typeRole: null,
    description: null,
    isActive: false,

    imageBlob: null,
    showPassword: false,

    showResultDialog: false,
    dialogResult: null
  }),
  mounted() {
    if (this.$route.query.isNew === 'true') {
      this.isNew = true
      this.description = new Editor({
        content: '',
      })
    } else {
      this.getData();
      this.isNew = false
    }
  },
  methods: {
    submitNew() {
      this.loading = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);
      let formdata = new FormData();
      formdata.append("Password", this.password);
      formdata.append("ConfirmPassword", this.password);
      formdata.append("IsActive", this.isActive);
      formdata.append("Email", this.email);
      formdata.append("TypeRole", this.typeRole);

      if (this.name !== null) {
        formdata.append("Name", this.name);
      }
      if (this.family !== null) {
        formdata.append("Family", this.family);
      }

      if (this.nationalCode !== null) {
        formdata.append("NationalCode", this.nationalCode);
      }
      if (this.phoneNumber !== null) {
        formdata.append("PhoneNumber", this.phoneNumber);
      }
      if (this.description !== "<p></p>") {
        formdata.append("Description", this.description.getHTML());
      }

      if (this.imageBlob !== null)
        formdata.append("ImageFileUrl", this.imageBlob, "user.jpg");

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/DefineUser", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.loading = false
              this.$router.push({path: '/Admin/ListUsers?role=' + this.typeRole})
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));
    },
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditUser?AppUserID=" +
          this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            this.loading = false
            let json = JSON.parse(result)
            this.typeRole = json.typeRole
            this.name = json.name
            this.family = json.family
            this.password = json.password
            this.phoneNumber = json.phoneNumber
            this.email = json.email
            if (json.nationalCode !== null) {
              this.nationalCode = json.nationalCode
            }
            this.isActive = json.isActive
            this.fileUrl = json.fileUrl;
            this.description = new Editor({
              content: json.description,
            })

          })
          .catch(error => console.log('error', error));

    },
    applyEdit() {
      this.loading = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);
      let formdata = new FormData();
      formdata.append("ID", this.$route.query.id);
      formdata.append("Password", this.password);
      formdata.append("ConfirmPassword", this.password);
      formdata.append("IsActive", this.isActive);
      formdata.append("Email", this.email);
      formdata.append("TypeRole", this.typeRole);

      if (this.name !== null) {
        formdata.append("Name", this.name);
      }
      if (this.family !== null) {
        formdata.append("Family", this.family);
      }

      if (this.nationalCode !== null) {
        formdata.append("NationalCode", this.nationalCode);
      }
      if (this.phoneNumber !== null) {
        formdata.append("PhoneNumber", this.phoneNumber);
      }
      if (this.description !== "<p></p>") {
        formdata.append("Description", this.description.getHTML());
      }

      if (this.imageBlob !== null)
        formdata.append("ImageFileUrl", this.imageBlob, "user.jpg");

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditUser", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.loading = false
              this.$router.push({path: '/Admin/ListUsers?role=' + this.typeRole})
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
