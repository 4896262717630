<template>
  <layout-default page-title="Settings" page-id="settingsPage">
    <v-data-table
        id="dataTable"
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :light="true"
        :mobile-breakpoint="680"
        :loading="loading"
        class="data-table"
        fixed-header
        item-key="settingId"
    >
      <template v-slot:[`item.count`]="{ index }">
        <div>{{ index + 1 }}</div>
      </template>

      <template v-slot:[`item.TypeSetting`]="{ item }">
        <div class="p-2 d-flex justify-content-center">
          {{ $typePages[item.TypePage - 1].name }}
        </div>
      </template>

      <template v-slot:[`item.modifiedDate`]="{item}">
        <div>{{ $displayDateTime(item.modifiedDate) }}</div>
      </template>

      <template v-slot:[`item.value`]="{item}">
        <div v-html="item.value"></div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            color="primary"
            elevation="0"
            :to="{path: 'EditSetting',
            query:{isNew:false , id:item.settingId}}"
        >
          <i class="fas fa-edit white--text"></i>
        </v-btn>
        <!--        <v-btn
                    color="primary"
                    elevation="0"
                    @click="deleteItem(item.BannerId)"
                    class="mr-2"
                >
                  <i class="fas fa-trash-alt"></i>
                </v-btn>-->
      </template>
    </v-data-table>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'SettingsList',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    expanded: [],
    singleExpand: true,
    headers: [
      {text: 'row', value: 'count', sortable: false,},
      {text: 'Name', value: 'displayName', sortable: false,},
      {text: 'Value', value: 'value', sortable: false,},
      {text: 'Modified Date', value: 'modifiedDate', sortable: false,},
      {text: '', value: 'actions', sortable: false,},
    ],
    list: [],
    search: null,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/ListSetting", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result)
            this.loading = false
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
