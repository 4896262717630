<template>
  <layout-default page-title="List Service Items" page-id="listServiceItemsPage">
    <v-data-table
        :single-expand="singleExpand"
        id="dataTable"
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="bannerId"
    >


      <template v-slot:[`item.isActive`]="{ item }">
        <div v-if="item.isActive === true">Yes</div>
        <div v-else>No</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            color="primary"
            elevation="0"
            :to="{path: 'DefineServiceItem',
            query:{isNew:false , id:item.serviceItemId}}">
          <i class="fas fa-edit white--text"></i>
        </v-btn>
      </template>
      <template v-slot:[`item.modifiedDate`]="{ item }">
        {{ $displayDateTime(item.modifiedDate) }}
      </template>
    </v-data-table>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ListServiceItems',
  components: {LayoutDefault},
  data: () => ({
    loading: true,
    headers: [
      {text: 'Name', value: 'name', sortable: false,},
      {text: 'Price', value: 'price', sortable: false,},
      {text: 'Active', value: 'isActive', sortable: false,},
      {text: 'Date and Hour', value: 'modifiedDate', sortable: false,},
      {text: 'Edit', value: 'actions', sortable: false,},
    ],
    list: [],
    expanded: [],
    singleExpand: true,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/ServiceItemList", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result)
            this.loading = false
          })
          .catch(error => console.log('error', error));
    },
  }
};
</script>