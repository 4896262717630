import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import router from "./router";


import "@/assets/styles/master.css";
import "@/assets/styles/fontawesome.min.css";
import "@/assets/styles/vuetify.css";
import "@/assets/styles/all.min.css";
import "@/assets/styles/bootstrap.min.css";
import "@/assets/styles/materialdesignicons.min.css";

import goTo from 'vuetify/lib/services/goto';
import VuePreferences, {preference} from 'vue-preferences'
import VuePageTransition from 'vue-page-transition'

import VueMeta from 'vue-meta';

Vue.prototype.$baseUrl = "https://pwa.gradxteam.com/";
//Vue.prototype.$baseUrl = "https://localhost:7007/";

Vue.prototype.$typePage = [
    {id: 1, name: 'About Us'},
    {id: 2, name: 'Contact Us'},
    {id: 3, name: 'Terms and conditions'},
    {id: 4, name: 'About App'}
];

Vue.prototype.$roles = [
    {
        id: 1,
        name: 'Administrator'
    }, {
        id: 2,
        name: 'SuperVisor'
    }, {
        id: 3,
        name: 'Client'
    }
]

Vue.prototype.$commentType =
    [{id: '1', text: 'Criticism'}, {id: '2', text: 'Suggestion'}];
Vue.prototype.$typeOrderStatus = [
    {id: '1', text: 'Waiting For Payment'},
    {id: '2', text: 'Payment Accepted'},
    {id: '3', text: 'Pending Start'},
    {id: '4', text: 'Started'},
    {id: '5', text: 'Finished'},
    {id: '6', text: 'Client Reply'},
    {id: '7', text: 'Operator Reply'},
    {id: '8', text: 'Canceled By Client'},
    {id: '9', text: 'Canceled By Operator'}
];

Vue.prototype.$typePayment = [
    {id: '1', text: 'Online'},
    {id: '2', text: 'By Admin'},
    {id: '3', text: 'Offline'},
    {id: '4', text: 'ByPass'}
];

Vue.prototype.$mandatory = [v => !!v || 'Please fill up the field'];
Vue.prototype.$emailRules = [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(v) || 'Invalid Email address'];

Vue.prototype.$replaceHttps = function (httpUrl) {
    if (httpUrl !== null) {
        return httpUrl.replace('http://', 'https://');
    }
};

Vue.prototype.$numberWithCommas = function (x) {
    if (x !== null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

Vue.prototype.$scrollTop = function (x) {
    return goTo(x);
}

Vue.prototype.$displayDateTime = function (dateTimeString) {
    const temp = dateTimeString.split('T')
    return (temp[0] + " " + temp[1].split('.')[0]);
}


Vue.use(VuePreferences, {
    storage: window.localStorage, namespace: 'geradex'
});
Vue.use(VueMeta);


Vue.use(VuePageTransition)


Vue.config.productionTip = false

Vue.prototype.$setUserData = function (email, password, token, role) {
    preference('userData', {defaultValue: '{"email":"", "password":"","token":"","role":""}'})
        .set(JSON.stringify({'email': email, 'password': password, 'token': token, 'role': role}));
};
Vue.prototype.$getUserData = function () {
    return JSON.parse(preference('userData', {defaultValue: '{"email":"", "password":"","token":"","role":""}'}).get());
};


new Vue({
    router, vuetify, render: h => h(App)
}).$mount('#app')
