<template>
  <layout-default page-title="Logs" page-id="logsPage">
    <v-data-table
        id="dataTable"
        :headers="headers"
        :items="list"
        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="exceptionLogId">

      <template v-slot:[`item.modifiedDate`]="{ item }">
        {{ $displayDateTime(item.modifiedDate) }}
      </template>


    </v-data-table>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ExceptionLogs',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    list: [],
    expanded: [],
    singleExpand: true,
    headers: [
      {text: 'Method Name', value: 'methodName', sortable: false,},
      {text: 'ExMessage', value: 'exMessage', sortable: false,},
      {text: 'Date', value: 'modifiedDate', sortable: false,},
      {text: 'AppUserId', value: 'appUserId', sortable: false,},

    ],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let url = "Admin/Logs";

      fetch(this.$baseUrl + url, requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result)
            this.loading = false
          })
          .catch(error => console.log('error', error));


    },
  }
};
</script>

