<template>
  <layout-default page-title="Payments" page-id="paymentsPage">
    <div class="row">
      <div class="col-md-4 py-1 col-12 mx-auto">
        <v-autocomplete
            :items="paymentStatusList"
            v-model="paymentStatusId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="name"
            item-value="value"
            @change="updateQueryFields('paymentStatusId', paymentStatusId)"
            label="Payment Status">
        </v-autocomplete>
      </div>
      <div class="col-md-4 py-1 col-12 mx-auto">
        <v-autocomplete
            :items="clients"
            v-model="selectedClientId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="family"
            item-value="id"
            @change="updateQueryFields('selectedClientId', selectedClientId)"
            label="Paid By">
        </v-autocomplete>
      </div>
      <div class="col-md-4 py-1 col-12 mx-auto" v-if="sum !== null">
        <v-alert
                 color="primary"
                 class=" text-center py-2">
          <span class="white--text">Total: {{ $numberWithCommas(sum) }}</span>
        </v-alert>
      </div>
    </div>
    <v-data-table
        id="dataTable"
        :headers="headers"
        :items="list"

        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="orderId">
      <template v-slot:[`item.totalAmount`]="{ item }">
        {{ $numberWithCommas(item.totalAmount) }}
      </template>
      <template v-slot:[`item.modifiedDate`]="{ item }">
        {{ $displayDateTime(item.modifiedDate) }}
      </template>

      <template v-slot:[`item.typePayment`]="{ item }">
        {{ $typePayment[item.typePayment - 1].text }}
      </template>

      <template v-slot:[`item.appUser`]="{ item }">
        {{ item.appUser.name +  " " + item.appUser.family }}
      </template>

      <template v-slot:[`item.isSuccessful`]="{ item }">
        <div v-if="item.isSuccessful === true" class="primary-text">Paid</div>
        <div v-else class="red--text">Not Paid</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn
            color="primary"
            elevation="0"
            class="ma-1"
            small
            :to="{path: 'OrderDetails', query:{id:item.orderId}}">
          <i class="fas fa-info-square white--text"></i>
        </v-btn>

      </template>

    </v-data-table>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ClientPayments',
  components: {LayoutDefault},
  data: () => ({
    loading: false,

    paymentStatusList: [
      {name: 'Paid', value: "true"},
      {name: 'Not Paid', value: "false"},
    ],
    paymentStatusId: null,

    clients: [],
    selectedClientId: null,

    pageSize: 10,
    pageIndex: 1,
    total: 0,

    expanded: [],
    singleExpand: true,
    headers: [
      {text: 'Order Number', value: 'order.orderNumber', sortable: false,},
      {text: 'Paid By', value: 'appUser', sortable: false,},
      {text: 'Order Subject', value: 'order.subject', sortable: false,},
      {text: 'Status', value: 'isSuccessful', sortable: false,},
      {text: 'Type', value: 'typePayment', sortable: false,},
      {text: 'Price', value: 'totalAmount', sortable: false,},
      {text: 'Result', value: 'result', sortable: false,},
      {text: 'Date', value: 'modifiedDate', sortable: false,},
      {text: 'Actions', value: 'actions', sortable: false,},
    ],
    list: [],

    sum: null

  }),
  watch: {
    "$route.query"() {
      if (this.$route.query.pageIndex !== undefined) {
        this.pageIndex = parseInt(this.$route.query.pageIndex);
      } else {
        this.pageIndex = 1;
      }

      if (this.$route.query.paymentStatusId !== undefined) {
        this.paymentStatusId = this.$route.query.paymentStatusId;
      }

      if (this.$route.query.selectedClientId !== undefined) {
        this.selectedClientId = this.$route.query.selectedClientId;
      }

      this.getData();

    }
  },
  mounted() {
    this.loadFilters();

  },
  methods: {
    loadFilters() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/PaymentFields", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.clients = JSON.parse(result).clients;

            this.loading = false;

            if (this.$route.query.pageIndex !== undefined) {
              this.pageIndex = parseInt(this.$route.query.pageIndex);
            }

            if (this.$route.query.paymentStatusId !== undefined) {
              this.paymentStatusId = this.$route.query.paymentStatusId;
            }

            if (this.$route.query.selectedClientId !== undefined) {
              this.selectedClientId = this.$route.query.selectedClientId;
            }

            this.getData();

          })
          .catch(error => console.log('error', error));
    },

    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let url = "Admin/Payments?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;



      if (this.paymentStatusId !== null) {
        url += "&isSuccessful=" + this.paymentStatusId;
      }

      if (this.selectedClientId !== null) {
        url += "&appUserId=" + this.selectedClientId;
      }

      fetch(this.$baseUrl + url, requestOptions)
          .then(response => response.text())
          .then(result => {
            const json = JSON.parse(result);
            this.list = json.list
            this.total = json.totalCount;
            this.sum = json.sum;
            this.loading = false
          })
          .catch(error => console.log('error', error));


    },
    pageChange(event) {
      if (this.pageIndex !== event.page) {
        this.updateQueryFields("pageIndex", event.page, true);
      }
    },
    updateQueryFields(key, value, isPageChanged = false) {
      const queryList = {};
      Object.assign(queryList, this.$route.query);
      queryList[key] = value;
      if (!isPageChanged) {
        delete queryList.pageIndex;
      }
      this.$router.push({query: queryList});
    },

  }
};
</script>
