<template>
  <layout-default page-title="Edit Setting" page-id="editSettingPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-md-4 col-12">
                <v-text-field
                    v-model="displayName"
                    :rules="$mandatory"
                    readonly
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Name">
                </v-text-field>
              </div>
              <div class="col-md-4 col-12">
                <v-text-field
                    v-model="value"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Value">
                </v-text-field>
              </div>
              <div class="col-md-4 col-12">
                <v-text-field
                    v-model="modifiedDate"
                    readonly
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Link">
                </v-text-field>
              </div>
            </div>
            <div class="col-12 text-center">
              <v-btn :disabled="!isFormValid"
                     color="primary"
                     elevation="0"
                     rounded
                     @click="editSetting">
                <span class="white--text">Apply Edit</span>
              </v-btn>

            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'EditSetting',
  components: {LayoutDefault},
  data: () => ({
    isFormValid: false,
    loading: false,
    displayName: null,
    modifiedDate: null,
    value: null,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditSetting?settingid=" + this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {

            let json = JSON.parse(result);
            this.loading = false;
            this.displayName = json.displayName;
            this.value = json.value;
            this.modifiedDate = this.$displayDateTime(json.modifiedDate)

          })
          .catch(error => console.log('error', error));
    },
    editSetting() {
      this.showLoaderDialog = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("Value", this.value);
      urlencoded.append("ModifiedDate", this.modifiedDate);
      urlencoded.append("SettingID", this.$route.query.id);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditSetting", requestOptions)
          .then(response => response.text())
          .then(() => {
            this.loading = false
            this.$router.push({path: '/Admin/SettingsList'})
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
