<template>
  <layout-default page-title="New Orders" page-id="newOrdersPage">
    <div class="row">
      <div class="col-md-4 py-1 col-12">
        <v-autocomplete
            :items="serviceItems"
            v-model="selectedServiceId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="name"
            item-value="serviceItemId"
            @change="updateQueryFields('serviceItemId', selectedServiceId)"
            label="Service">
        </v-autocomplete>
      </div>
      <div class="col-md-4 py-1 col-12">
        <v-autocomplete
            :items="paymentStatusList"
            v-model="paymentStatusId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="name"
            item-value="value"
            @change="updateQueryFields('paymentStatusId', paymentStatusId)"
            label="Payment Status">
        </v-autocomplete>
      </div>
      <div class="col-md-4 py-1 col-12">
        <v-autocomplete
            :items="$typeOrderStatus"
            v-model="selectedStatusId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="text"
            item-value="id"
            @change="updateQueryFields('selectedStatusId', selectedStatusId)"
            label="Order Status">
        </v-autocomplete>
      </div>
      <div class="col-md-4 py-1 col-12 mx-auto">
        <v-autocomplete
            :items="clients"
            v-model="selectedClientId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="family"
            item-value="id"
            @change="updateQueryFields('selectedClientId', selectedClientId)"
            label="User">
        </v-autocomplete>
      </div>
<!--      <div class="col-md-4 py-1 col-12 mx-auto">
        <v-autocomplete
            :items="agents"
            v-model="selectedAgentId"
            dense
            flat
            outlined
            clearable
            hide-details
            color="primary"
            item-text="family"
            item-value="id"
            @change="updateQueryFields('selectedAgentId', selectedAgentId)"
            label="Agent">
        </v-autocomplete>
      </div>-->
      <div class="col-md-4 py-1 col-12 mx-auto">
        <v-alert v-if="sum !== null"
                 color="primary"
                 class=" text-center py-2">
          <span class="white--text">Total: {{ $numberWithCommas(sum) }}</span>
        </v-alert>
      </div>
    </div>
    <v-data-table
        id="dataTable"
        :headers="headers"
        :items="list"
        :page="pageIndex"
        :items-per-page="pageSize"
        :server-items-length="total"
        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="orderId"
        @pagination="pageChange">
      <template v-slot:[`item.isPayed`]="{ item }">
        <div v-if="item.isPayed === true" class="primary-text">Yes</div>
        <div v-else class="red--text">No</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            v-if="!item.isPayed"
            color="primary"
            class="ma-1"
            elevation="0"
            small
            @click="showPaymentDialog(item)">
          <i class="fas fa-credit-card-alt white--text"></i>
        </v-btn>
        <v-btn
            color="primary"
            elevation="0"
            class="ma-1"
            small
            :to="{path: '/Admin/OrderDetails', query:{id:item.orderId}}">
          <i class="fas fa-info-square white--text"></i>
        </v-btn>
        <v-btn v-if="!item.isPayed &&
        item.typeOrderStatus !== 8 && item.typeOrderStatus !== 9"
               color="red"
               elevation="0"
               small
               class="ma-1"
               @click="showCancellationDialog(item)">
          <i class="fas fa-times-to-slot white--text"></i>
        </v-btn>

      </template>

      <template v-slot:[`item.typeOrderStatus`]="{ item }">
        {{ $typeOrderStatus[item.typeOrderStatus - 1].text }}
      </template>

      <template v-slot:[`item.serviceItem`]="{ item }">
        {{ item.serviceItem.name }}
      </template>
      <template v-slot:[`item.totalAmount`]="{ item }">
        {{ $numberWithCommas(item.totalAmount) }}
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        {{ $displayDateTime(item.createdDate) }}
      </template>
      <template v-slot:[`item.updatedDate`]="{ item }">
        {{ $displayDateTime(item.updatedDate) }}
      </template>


    </v-data-table>
    <v-dialog
        v-model="paymentDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Payment</span>
          <v-btn
              icon
              small
              @click="closePaymentDialog">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 pb-0">
          <div class="mb-0 black--text" v-if="selectedOrderToPay !== null">
            <p class="text-center">
              You are about to pay the order "{{ selectedOrderToPay.subject }}"
              <br/>
              Amount: {{ selectedOrderToPay.totalAmount }}
            </p>
          </div>
        </v-card-text>
        <v-card-subtitle>
          <div class="row">
            <div class="col-6 text-left py-0 ">
              <v-btn color="orange"
                     elevation="0"
                     rounded
                     @click="closePaymentDialog">
                <span class="white--text">Dismiss</span>
              </v-btn>
            </div>
            <div class="col-6 text-right py-0">
              <v-btn color="primary"
                     elevation="0"
                     rounded
                     @click="submitPayment">
                <span class="white--text">Pay</span>
              </v-btn>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'NewOrders',

  components: {LayoutDefault},
  data: () => ({
    loading: false,
    pageSize: 10,
    pageIndex: 1,
    total: 0,

    expanded: [],
    singleExpand: true,
    headers: [
      {text: 'Order Number', value: 'orderNumber', sortable: false,},
      {text: 'Subject', value: 'subject', sortable: false,},
      {text: 'Status', value: 'typeOrderStatus', sortable: false,},
      {text: 'Service', value: 'serviceItem', sortable: false,},
      {text: 'Price', value: 'totalAmount', sortable: false,},
      {text: 'Paid', value: 'isPayed', sortable: false,},
      {text: 'Submitted', value: 'createdDate', sortable: false,},
      {text: 'Updated', value: 'updatedDate', sortable: false,},
      {text: 'Actions', value: 'actions', sortable: false,},
    ],
    list: [],

    serviceItems: [],
    selectedServiceId: null,
    selectedStatusId: null,
    isPayed: null,

    paymentStatusList: [
      {name: 'Paid', value: "true"},
      {name: 'Not Paid', value: "false"},
    ],
    paymentStatusId: null,

    paymentDialog: false,
    selectedOrderToPay: null,

    showResultDialog: false,
    dialogResult: null,

    cancellationDialog: false,
    selectedOrderToCancel: null,

    clients:[],
    selectedClientId: null,

    agents:[],
    selectedAgentId: null,

    sum: null
  }),
  watch: {
    "$route.query"() {
      if (this.$route.query.pageIndex !== undefined) {
        this.pageIndex = parseInt(this.$route.query.pageIndex);
      } else {
        this.pageIndex = 1;
      }

      if (this.$route.query.selectedServiceId !== undefined) {
        this.selectedServiceId = this.$route.query.selectedServiceId;
      }

      if (this.$route.query.paymentStatusId !== undefined) {
        this.paymentStatusId = this.$route.query.paymentStatusId;
      }

      if (this.$route.query.selectedStatusId !== undefined) {
        this.selectedStatusId = this.$route.query.selectedStatusId;
      }

      if (this.$route.query.selectedAgentId !== undefined) {
        this.selectedAgentId = this.$route.query.selectedAgentId;
      }

      if (this.$route.query.selectedClientId !== undefined) {
        this.selectedClientId = this.$route.query.selectedClientId;
      }

      this.getData();

    }
  },
  mounted() {
    this.loadFilters();
  },
  methods:{
    loadFilters() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/OrderFields", requestOptions)
          .then(response => response.text())
          .then(result => {
            const json = JSON.parse(result);
            this.serviceItems = json.serviceItems;
            this.clients = json.clients;
            this.agents = json.agents;

            this.loading = false;

            if (this.$route.query.pageIndex !== undefined) {
              this.pageIndex = parseInt(this.$route.query.pageIndex);
            }

            if (this.$route.query.selectedServiceId !== undefined) {
              this.selectedServiceId = this.$route.query.selectedServiceId;
            }

            if (this.$route.query.paymentStatusId !== undefined) {
              this.paymentStatusId = this.$route.query.paymentStatusId;
            }

            if (this.$route.query.selectedStatusId !== undefined) {
              this.selectedStatusId = this.$route.query.selectedStatusId;
            }

            if (this.$route.query.selectedClientId !== undefined) {
              this.selectedClientId = this.$route.query.selectedClientId;
            }

            if (this.$route.query.selectedAgentId !== undefined) {
              this.selectedAgentId = this.$route.query.selectedAgentId;
            }

            this.getData();

          })
          .catch(error => console.log('error', error));
    },
    getData() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let url = "Admin/NewOrders?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      url += "&agentUserId=null" ;

      if (this.selectedServiceId !== null) {
        url += "&serviceId=" + this.selectedServiceId;
      }
      if (this.selectedStatusId !== null) {
        url += "&orderStatus=" + this.selectedStatusId;
      }
      if (this.paymentStatusId !== null) {
        url += "&isPayed=" + this.paymentStatusId;
      }

      if (this.selectedClientId !== null) {
        url += "&appUserId=" + this.selectedClientId;
      }

      fetch(this.$baseUrl + url, requestOptions)
          .then(response => response.text())
          .then(result => {
            const json = JSON.parse(result);
            this.list = json.list
            this.total = json.totalCount;
            this.sum = json.sum;
            this.loading = false
          })
          .catch(error => console.log('error', error));


    },
    pageChange(event) {
      if (this.pageIndex !== event.page) {
        this.updateQueryFields("pageIndex", event.page, true);
      }
    },
    updateQueryFields(key, value, isPageChanged = false) {
      const queryList = {};
      Object.assign(queryList, this.$route.query);
      queryList[key] = value;
      if (!isPageChanged) {
        delete queryList.pageIndex;
      }
      this.$router.push({query: queryList});
    },
    showPaymentDialog(item) {
      console.log(item);
      this.selectedOrderToPay = item;
      this.paymentDialog = true;
    },
    closePaymentDialog() {
      this.paymentDialog = false;
      this.selectedOrderToPay = null;
    },
    submitPayment() {
      let resStatus = null
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();

      formdata.append("orderId", this.selectedOrderToPay.orderId);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/PayOrder", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.paymentDialog = false;
            if (resStatus === 200) {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
              this.getData();
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }

          })
          .catch(error => console.log('error', error));
    },
  }
};
</script>
