import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#47bdad",
                whiteColor: "#F2F2F2",
                blackColor: "#707070",
                grayColor: "#ECECEC",
            },
        },
    },
});
