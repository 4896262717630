<template>
  <layout-default page-title="Define Page" page-id="definePagePage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-12 text-center py-0">
                <input v-show="false" ref="inputUpload" accept=".jpg,.jpeg,.gif,.png" type="file"
                       @change="attachmentSelected"/>
                <div id="coverImage"
                     class="bg-white rounded-lg d-flex justify-content-center mr-auto ml-1 box-preview-image align-items-center text-center">
                  <i v-if="!hasPhoto" class="fas fa-plus fa-xl pointer"
                     @click="$refs.inputUpload.click()"></i>
                  <v-btn v-else color="primary" fab x-small @click="removeCoverPhoto()">
                    <i class="fas fa-times  fa-xl pointer"></i>
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </div>
              <div class="col-md-6 col-12">
                <v-autocomplete
                    :items="$typePage"
                    v-model="typePage"
                    :rules="$mandatory"
                    :readonly="!isNew"
                    dense
                    flat
                    outlined
                    color="primary"
                    item-text="name"
                    item-value="id"
                    label="Page Type">
                </v-autocomplete>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="link"
                    dense
                    flat
                    outlined
                    color="primary"
                    placeholder="e.g. https://google.com"
                    label="Link">
                </v-text-field>
              </div>
              <div class="col-12">
                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <v-btn v-if="isNew"
                       :disabled="!isFormValid || description.getHTML() === '<p></p>'"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>
                <v-btn v-else
                       :disabled="!isFormValid || description === null || description.getHTML() === '<p></p>'"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="applyEdit">
                  <span class="white--text">Apply Edit</span>
                </v-btn>
              </div>

            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';

export default {
  name: 'DefinePage',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    isFormValid: false,
    loading: false,
    isNew: false,

    hasPhoto: false,
    hasPhotoUrl: false,

    selectedImage: null,
    typePage: null,
    link: null,
    description: null,
    selectedImageCoverFilePath: null,

    showResultDialog: false,
    dialogResult: null
  }),
  mounted() {
    if (this.$route.query.isNew === 'true') {
      this.isNew = true
      this.description = new Editor({
        content: '',
      })
    } else {
      this.getData();
      this.isNew = false
    }
  },
  methods: {
    attachmentSelected(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.hasPhoto = true
        this.hasPhotoUrl = false;
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          document.getElementById('coverImage').style.backgroundImage = "url(" + fr.result + ")"
          this.selectedImageCoverFilePath = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.hasPhoto = false;
        this.hasPhotoUrl = true;
      }
    },
    removeCoverPhoto() {
      document.getElementById('coverImage').style.backgroundImage = ""
      this.hasPhoto = false
    },
    submitNew() {
      let resStatus = null
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();

      if (this.description !== '') {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("ImageFileUrl", this.selectedImageCoverFilePath);

      formdata.append("TypePage", this.typePage);

      if (this.link !== '' && this.link !== "null" && this.link !== null) {
        formdata.append("Link", this.link);
      }

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/DefinePage", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.loading = false
              this.$router.push({path: '/Admin/ListPages'})
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }

          })
          .catch(error => console.log('error', error));
    },
    getData() {
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditPage?PageId=" + this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)
            this.loading = false

            this.typePage = json.typePage
            if (json.fileUrl !== null) {
              this.hasPhoto = true
              this.hasPhotoUrl = true;
              document.getElementById('coverImage').style.backgroundImage = "url(" + json.fileUrl + ")"
            }
            this.link = json.link
            this.selectedImageCoverFilePath = json.fileUrl
            this.description = new Editor({
              content: json.description,
            })
          })
          .catch(error => console.log('error', error));

    },
    applyEdit() {
      let resStatus = null

      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      if (this.hasPhoto) {
        if (this.hasPhotoUrl) {
          formdata.append("FileUrl", this.selectedImageCoverFilePath);
        } else {
          formdata.append("ImageFileUrl", this.selectedImageCoverFilePath);

        }
      }
      if (this.description !== '') {
        formdata.append("Description", this.description.getHTML());
      }
      formdata.append("PageId", this.$route.query.id);
      formdata.append("TypePage", this.typePage);
      if (this.link !== '' && this.link !== "null" && this.link !== null) {
        formdata.append("Link", this.link);
      }


      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditPage", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {

            if (resStatus === 200) {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
              this.$router.push({path: '/Admin/ListPages'})
            } else {
              this.loading = false
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
