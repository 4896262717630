<template>
  <div class="container-fluid" :id="pageId">
    <v-app-bar app class="primary-bg ">
      <div class="row justify-content-between  pa-0">
        <div class="col-auto text-left px-0">
          <!-- TODO do the back button for non-dashboard pages
                Whether instead of menu or logo -->
          <v-btn
              text
              color="white"
              @click.stop="drawer = !drawer">
            <i class="fas fa-bars font-20"></i>
          </v-btn>
        </div>

        <div class="col px-0 text-center">
          <span class="title white--text">{{ pageTitle }}</span></div>


        <div class="col-auto text-right app-bar-display">
          <img src="../assets/img/logo-type-white.png" height="40" alt="Gradex Logo">
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer mobile-breakpoint="769" app v-model="drawer" style="background: teal;">
      <div class="px-2 mt-2 d-flex justify-content-center ">
        <v-img src="../assets/img/logo-type-white.png" contain height="40"></v-img>
      </div>
      <v-divider></v-divider>

      <!-- Client Menu -->
      <div v-if="role === 3">
        <div class="mt-3 mx-3">
          <router-link to="/Dashboard" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Dashboard</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Orders</div>

          <router-link to="/NewOrder" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">New Order</span>
          </router-link>
          <router-link to="/MyOrders" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">My Orders</span>
          </router-link>
          <router-link to="/PaymentHistory" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Payment History</span>
          </router-link>
          <v-divider></v-divider>

        </div>

        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Gradex</div>
          <router-link to="/AboutUs" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">About Us</span>
          </router-link>
          <router-link to="/ContactUs" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Contact Us</span>
          </router-link>
          <router-link to="/TermsConditions" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Terms and Conditions</span>
          </router-link>
          <router-link to="/AboutApp" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">About App</span>
          </router-link>
          <router-link to="/SocialNetworks" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Social Networks</span>
          </router-link>
          <router-link to="/SuggestionCritic" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Criticisms and Suggestions</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <router-link to="/Profile" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Profile</span>
          </router-link>
        </div>
      </div>
      <!-- Admin Menu -->
      <div v-else>
        <div class="mt-3 mx-3">
          <router-link to="/Admin/Dashboard" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Dashboard</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Banners</div>
          <router-link to="/Admin/DefineBanner?isNew=true" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Define</span>
          </router-link>
          <router-link to="/Admin/ListBanners" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">List</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Service Item</div>
          <router-link to="/Admin/DefineServiceItem?isNew=true" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Define</span>
          </router-link>
          <router-link to="/Admin/ListServiceItems" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">List</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Orders</div>
          <router-link to="/Admin/NewOrders" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">New Orders</span>
          </router-link>
          <router-link to="/Admin/ClientOrders" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Client Orders</span>
          </router-link>
          <router-link to="/Admin/ClientPayments" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Client Payments</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Page</div>
          <router-link to="/Admin/DefinePage?isNew=true" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Define</span>
          </router-link>
          <router-link to="/Admin/ListPages" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">List</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Social Networks</div>
          <router-link to="/Admin/DefineSocial?isNew=true" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Define</span>
          </router-link>
          <router-link to="/Admin/ListSocials" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">List</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <div class="menu-header text-center white--text">Users</div>
          <router-link to="/Admin/DefineUser?isNew=true" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Define</span>
          </router-link>
          <router-link to="/Admin/ListUsers" class="white--text d-block mt-2">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">List</span>
          </router-link>
          <v-divider></v-divider>
        </div>
        <div class="mt-3 mx-3">
          <router-link to="/Admin/Profile" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Profile</span>
          </router-link>
        </div>
        <div class="mt-3 mx-3">
          <router-link to="/Admin/SettingsList" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Settings</span>
          </router-link>
        </div>
        <div class="mt-3 mx-3">
          <router-link to="/Admin/ListSuggests" class="white--text d-block">
            <i class="fas fa-circle secondary-text font-12"></i>
            <span class="ml-2">Criticisms and Suggestions</span>
          </router-link>
        </div>
      </div>

      <div class="mt-3 mx-3">
        <router-link to="/Logout" class="white--text d-block">
          <i class="fas fa-circle secondary-text font-12"></i>
          <span class="ml-2">Logout</span>
        </router-link>
      </div>
    </v-navigation-drawer>


    <v-main class="pa-3">
      <slot/>

    </v-main>
    <v-footer app class="text-center d-block">
      &copy; {{ new Date().getFullYear() }} All rights reserved. Gradex
    </v-footer>
  </div>
</template>

<script>

export default {
  name: 'layout-default',
  metaInfo() {
    return {
      title: window.projectName + this.$route.name,
    }
  },
  props: {
    pageId: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    drawer: false,
    role: null
  }),
  mounted() {
    if (!this.$getUserData().token) {
      this.$router.replace({path: '/'})
    }

    this.role = this.$getUserData().role;

    if (window.innerWidth > 769) {
      this.drawer = true
    }


  }
};
</script>
