<template>
  <layout-default page-title="My Profile" page-id="profilePage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-12">
                <ImagePicker :imgDataUrl="fileUrl"
                             title="Your Image"
                             @cropSuccess="blob => imageBlob = blob"/>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="name"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Name">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="family"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Family">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="email"
                    :rules="$emailRules"
                    type="email"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="E-mail">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="password"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    label="Password"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' :  'password'">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="nationalCode"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="National/Social Security Code">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="phoneNumber"
                    dense
                    flat
                    outlined
                    type="number"
                    color="primary"
                    label="Phone Number">
                </v-text-field>
              </div>
              <div class="col-12 text-center">
                <v-btn :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="postData">
                  <span class="white--text">Update Profile</span>
                </v-btn>
              </div>
            </div>
          </v-form>

        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import layoutDefault from "@/components/layout-default.vue";
import ImagePicker from "@/components/ImagePicker.vue";

export default {
  name: 'MyProfile',
  components: {ImagePicker, layoutDefault},
  data: () => ({
    loading: false,
    showResultDialog: false,
    dialogResult: null,

    email: null,
    password: null,
    name: null,
    family: null,
    fileUrl: null,
    nationalCode: null,
    phoneNumber: null,

    imageBlob: null,
    isFormValid: false,

    showPassword: false,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Authorization", this.$getUserData().token);


      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/MyProfile", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false
            if (resStatus !== 200) {
              this.dialogResult = result;
              this.showResultDialog = true
            } else {
              let json = JSON.parse(result);
              this.email = json.email;
              this.password = json.password;
              this.name = json.name;
              this.family = json.family;
              this.fileUrl = json.fileUrl;
              this.nationalCode = json.nationalCode;
              this.phoneNumber = json.phoneNumber;
            }
          })
          .catch(error => console.log('error', error));
    },
    postData() {
      let resStatus = null
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      formdata.append("Name", this.name);
      formdata.append("Family", this.family);
      formdata.append("Password", this.password);
      formdata.append("ConfirmPassword", this.password);
      formdata.append("PhoneNumber", this.phoneNumber);
      if (this.nationalCode !== null)
        formdata.append("NationalCode", this.nationalCode);
      formdata.append("Email", this.email);


      if (this.imageBlob !== null)
        formdata.append("ImageFileUrl", this.imageBlob, "user.jpg");


      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/MyProfile", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            if (resStatus === 200) {
              this.showResultDialog = true
              this.dialogResult = result
            } else {
              this.showResultDialog = true
              this.dialogResult = result
            }
            this.loading = false

          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
