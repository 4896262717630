<template>
  <layout-default page-title="Social Networks" page-id="socialNetworksPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <div class="row" v-if="socialNetworkItems.length > 0">
            <div v-for="item in socialNetworkItems" :key="item.socialNetworkID"
                 class="col-auto mt-9 text-center">
              <div v-if="item.isActive">
                <a :href="item.link" target="_blank">
                  <v-img :src="$replaceHttps(item.fileUrl)" :alt=item.name height="75" width="75" contain></v-img>
                </a>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'SocialNetworks',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    socialNetworkItems:[],
  }),
  mounted() {
    this.getData();
  },
  methods:{
    getData() {
      this.loading = true

      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/GetSocialNetworks", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.socialNetworkItems = JSON.parse(result);
            this.loading = false

          })
          .catch(error => console.log('error', error));
    },
  }
};
</script>
