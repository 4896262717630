<template>
  <layout-default page-title="Dashboard" page-id="dashboardPage">
    <div class="row page-padding">
      <div class="col-12 pt-0">
        <div
            v-if="banners.length > 0"
            class="row "
            dir="ltr">
          <carousel
              :autoplay="true"
              :dots="false"
              :items="1"
              :loop="false"
              :margin="5"
              :nav="false"
              :rtl="true"
              class="col-12 py-0 ">
            <div
                v-for="item in banners"
                :key="item.bannerId"
            >
              <v-img
                  v-if="item.fileUrl !== null"
                  contain
                  :src="$replaceHttps(item.fileUrl)"
                  @click="$openLink(item.link)">
              </v-img>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import carousel from "vue-owl-carousel";

export default {
  name: 'ClientDashboard',
  components: {LayoutDefault,carousel},
  data: () => ({
    banners:[],
  }),
  mounted() {
    this.getData();
  },
  methods:{
    getData(){
      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/GetBanners", requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)
            if (json.length > 0) {
              this.banners = JSON.parse(result);
            }
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>

