<template>
  <layout-default page-title="Criticisms and Suggestions" page-id="SuggestionCriticPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-md-6 col-12">
                <v-autocomplete
                    :items="$commentType"
                    v-model="commentType"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    item-text="text"
                    item-value="id"
                    label="Type">
                </v-autocomplete>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="subject"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Subject">
                </v-text-field>
              </div>
              <div class="col-12">

                <div class="">Description</div>
                <editor-content :editor="description" class="text-editor"/>
              </div>
              <div class="col-12 text-center">
                <v-btn
                    :disabled="!isFormValid || description.getHTML() === '<p></p>'"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submit">
                  <span class="white--text">Submit</span>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";
import {Editor, EditorContent} from 'tiptap';

export default {
  name: 'SuggestionCritic',
  components: {LayoutDefault, EditorContent},
  data: () => ({
    loading: false,
    isFormValid: false,

    commentType: null,
    subject: null,
    description: null,

    showResultDialog: false,
    dialogResult: null

  }),
  mounted() {
    this.description = new Editor({
      content: '',
    })
  },
  methods: {
    submit() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("Description",  this.description.getHTML());
      urlencoded.append("Subject", this.subject);
      urlencoded.append("TypeSuggestionCritic", this.commentType);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/SuggestionCritic", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.loading = false
            this.showResultDialog = true
            this.dialogResult = result
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
