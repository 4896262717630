<template>
  <layout-default page-title="Criticisms and Suggestions" page-id="listSuggestsPage">
    <v-data-table
        id="dataTable"
        :expanded.sync="expanded"
        show-expand
        :single-expand="singleExpand"
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :light="true"
        :mobile-breakpoint="680"
        :loading="loading"
        class="data-table"
        fixed-header
        item-key="suggestionCriticId"
    >


      <template v-slot:[`item.typeSuggestionCritic`]="{ item }">
        <div class="p-2 d-flex justify-content-center">
          {{ $commentType[item.typeSuggestionCritic - 1].text }}
        </div>
      </template>

      <template v-slot:[`item.modifiedDate`]="{item}">
        <div>{{ $displayDateTime(item.modifiedDate) }}</div>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td>
          <div v-if="item.description === null">
            Without Description
          </div>
          <div v-else>
            <div v-html="item.description"></div>
          </div>
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            color="primary"
            elevation="0"
            :to="{path: 'EditSetting',
            query:{isNew:false , id:item.settingId}}"
        >
          <i class="fas fa-edit white--text"></i>
        </v-btn>
        <!--        <v-btn
                    color="primary"
                    elevation="0"
                    @click="deleteItem(item.BannerId)"
                    class="mr-2"
                >
                  <i class="fas fa-trash-alt"></i>
                </v-btn>-->
      </template>
    </v-data-table>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'ListSuggests',
  components: {LayoutDefault},
  data: () => ({
    loading: true,
    headers: [
      {text: 'Subject', value: 'subject', sortable: false,},
      {text: 'Type', value: 'typeSuggestionCritic', sortable: false,},
      {text: 'Description', value: 'data-table-expand', sortable: false,},
      {text: 'Date', value: 'modifiedDate', sortable: false,},
    ],
    list: [],
    expanded: [],
    singleExpand: true,
  }),
  mounted() {
    this.getData();
  },
  methods:{
    getData() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/SuggestionCritic", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result)
            this.loading = false
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
