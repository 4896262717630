<template>
  <layout-default page-title="Payment History" page-id="paymentHistoryPage">
    <div class="row">
      <div class="col-md-4 col-12 mx-auto">
        <v-autocomplete
            :items="paymentStatusList"
            v-model="paymentStatusId"
            dense
            flat
            outlined
            clearable
            color="primary"
            item-text="name"
            item-value="value"
            @change="getData"
            label="Payment Status">
        </v-autocomplete>
      </div>

    </div>
    <v-data-table
        id="dataTable"
        :headers="headers"
        :items="list"

        :light="true"
        :mobile-breakpoint="680"
        class="data-table"
        :loading="loading"
        fixed-header
        item-key="orderId">
      <template v-slot:[`item.totalAmount`]="{ item }">
        {{ $numberWithCommas(item.totalAmount) }}
      </template>
      <template v-slot:[`item.modifiedDate`]="{ item }">
        {{ $displayDateTime(item.modifiedDate) }}
      </template>

      <template v-slot:[`item.typePayment`]="{ item }">
        {{ $typePayment[item.typePayment - 1].text }}
      </template>

      <template v-slot:[`item.isSuccessful`]="{ item }">
        <div v-if="item.isSuccessful === true" class="primary-text">Paid</div>
        <div v-else class="red--text">Not Paid</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn
            color="primary"
            elevation="0"
            class="ma-1"
            small
            :to="{path: 'OrderDetails', query:{id:item.orderId}}">
          <i class="fas fa-info-square white--text"></i>
        </v-btn>

      </template>

    </v-data-table>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'PaymentHistory',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    expanded: [],
    singleExpand: true,
    headers: [
      {text: 'Order Number', value: 'order.orderNumber', sortable: false,},
      {text: 'Order Subject', value: 'order.subject', sortable: false,},
      {text: 'Status', value: 'isSuccessful', sortable: false,},
      {text: 'Type', value: 'typePayment', sortable: false,},
      {text: 'Price', value: 'totalAmount', sortable: false,},
      {text: 'Result', value: 'result', sortable: false,},
      {text: 'Date', value: 'modifiedDate', sortable: false,},
      {text: 'Actions', value: 'actions', sortable: false,},
    ],
    list: [],

    paymentStatusList: [
      {name: 'Paid', value: "true"},
      {name: 'Not Paid', value: "false"},
    ],
    paymentStatusId: null,
  }),
  mounted() {
    this.getData();

  },
  methods: {
    getData(){
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let url = "Client/PaymentHistory";

      if (this.paymentStatusId !== null) {
        url += "?isSuccessful=" + this.paymentStatusId;
      }

      fetch(this.$baseUrl + url, requestOptions)
          .then(response => response.text())
          .then(result => {
            this.list = JSON.parse(result);
            this.loading = false;
          })
          .catch(error => console.log('error', error));
    }
  }
};
</script>
