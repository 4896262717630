<template>
  <layout-default page-title="Define Service Item" page-id="defineServiceItemPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <div class="row">
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="name"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    color="primary"
                    label="Name">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                    v-model="price"
                    :rules="$mandatory"
                    dense
                    flat
                    outlined
                    type="number"
                    color="primary"
                    label="Price">
                </v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-checkbox hide-details
                            class="mt-0"
                            v-model="isActive"
                            color="primary">
                  <template v-slot:label>
                    <span class="mt-2">Active</span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-12 col-md-6 text-center">
                <v-btn v-if="isNew"
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="submitNew">
                  <span class="white--text">Submit</span>
                </v-btn>
                <v-btn v-else
                       :disabled="!isFormValid"
                       color="primary"
                       elevation="0"
                       rounded
                       @click="applyEdit">
                  <span class="white--text">Apply Edit</span>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
        v-model="showResultDialog"
        hide-overlay
        persistent
        width="530">
      <v-card class="text-center">
        <v-card-title class="justify-space-between primary-bg pa-3">
          <span class="white--text">Result</span>
          <v-btn
              icon
              small
              @click="showResultDialog = false">
            <i class="fas fa-times font-16 ml-1"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="mb-0 black--text py-5">{{ dialogResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-default>
</template>
<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'DefineServiceItem',
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    isFormValid: false,

    isActive: false,
    name: null,
    price: null,

    isNew: false,
    showResultDialog : false,
    dialogResult: null,

  }),
  mounted() {
    if (this.$route.query.isNew === 'true') {
      this.isNew = true
    } else {
      this.getData();
      this.isNew = false
    }
  },
  methods: {
    submitNew() {
      this.loading = true;
      let resStatus = null
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);
      let formdata = new FormData();

      formdata.append("IsActive", this.isActive);
      formdata.append("Name", this.name);
      formdata.append("Price", this.price);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/DefineServiceItem", requestOptions)
          .then(response => {
            resStatus = response.status
            return response.text()
          })
          .then(result => {
            this.loading = false

            if (resStatus === 200) {
              this.$router.push({path: '/Admin/ListServiceItems'})
            } else {
              this.dialogResult = result
              this.showResultDialog = true
            }
          })
          .catch(error => console.log('error', error));

    },
    getData() {
      this.loading = true
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Admin/EditServiceItem?serviceItemId=" + this.$route.query.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)

            this.price = json.price;
            this.name = json.name;
            this.isActive = json.isActive;
            this.loading = false;
          })
          .catch(error => console.log('error', error));
    },
    applyEdit() {
      this.loading = true;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$getUserData().token);

      let formdata = new FormData();
      formdata.append("ServiceItemId", this.$route.query.id);
      formdata.append("IsActive", this.isActive);
      formdata.append("Name", this.name);
      formdata.append("Price", this.price);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(this.$baseUrl + "Admin/EditServiceItem", requestOptions)
          .then(response => response.text())
          .then(() => {
            this.loading = false
            this.$router.push({path: '/Admin/ListServiceItems'})
          })
          .catch(error => console.log('error', error));

    }
  }
};
</script>
