<template>
  <layout-default page-title="About App" page-id="aboutAppPage">
    <div class="row">
      <v-card
          :loading="loading"
          class="mx-auto col-11">
        <template v-slot:progress>
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text>
          <div class="row">
            <div class="col-11 mx-auto" v-if="fileUrl">
              <v-img :src="$replaceHttps(fileUrl)" class="mt-5"></v-img>
            </div>
            <div class="col-11 mx-auto" v-if="content" v-html="content">
            </div>
            <div class="col-11 mx-auto" v-if="link !== null">
              <a :href="link" target="_blank">
                <v-btn color="primary" elevation="0" class="mt-3">Link</v-btn>
              </a>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/layout-default.vue";

export default {
  name: 'AboutApp',
  components: {LayoutDefault},
  data: () => ({

    loading: false,
    content: null,
    fileUrl: null,
    link: null

  }),
  mounted() {
    this.getData();
  },
  methods:{
    getData() {
      this.showLoaderDialog = true

      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(this.$baseUrl + "Client/GetPage?typePage=4", requestOptions)
          .then(response => response.text())
          .then(result => {
            let json = JSON.parse(result)
            this.content = json.description
            this.fileUrl = json.fileUrl
            this.link = json.link
            this.loading = false

          })
          .catch(error => console.log('error', error));
    },
  }
};
</script>
